<template>
  <v-dialog v-model="dialog" scrollable max-width="600" @click:outside="reset">
    <v-card>
      <v-card-title class="headline">
        {{ isEditing ? "Edit" : "Add a new" }} Customer
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4" ref="formWrapper">
        <v-form @submit.prevent="save" method="post" id="customer-form">
          <v-text-field
            label="First Name *"
            v-model="fields.first_name"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('first_name')"
            :error-messages="errors['first_name']"
          ></v-text-field>
          <v-text-field
            label="Last Name *"
            v-model="fields.last_name"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('last_name')"
            :error-messages="errors['last_name']"
          ></v-text-field>
          <v-text-field
            label="Email *"
            v-model="fields.email"
            type="email"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('email')"
            :error-messages="errors['email']"
          ></v-text-field>
          <v-text-field
            label="Phone *"
            v-model="fields.phone"
            type="phone"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('phone')"
            :error-messages="errors['phone']"
          ></v-text-field>
          <v-text-field
            label="Address Line 1 *"
            v-model="fields.address1"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('address1')"
            :error-messages="errors['address1']"
          ></v-text-field>
          <v-text-field
            label="Address Line 2"
            v-model="fields.address2"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('address2')"
            :error-messages="errors['address2']"
          ></v-text-field>
          <v-text-field
            label="City *"
            v-model="fields.city"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('city')"
            :error-messages="errors['city']"
          ></v-text-field>
          <v-text-field
            label="Postcode *"
            v-model="fields.postcode"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('postcode')"
            :error-messages="errors['postcode']"
          ></v-text-field>
          <v-text-field
            label="County"
            v-model="fields.county"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('county')"
            :error-messages="errors['county']"
          ></v-text-field>
          <v-autocomplete
            label="Country *"
            v-model="fields.country"
            :items="availableCountries"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('country')"
            :error-messages="errors['country']"
          ></v-autocomplete>
          <v-switch
            label="Is Actively Looking"
            v-model="fields.is_actively_looking"
            inset
            :error="errors.hasOwnProperty('is_actively_looking')"
            :error-messages="errors['is_actively_looking']"
          ></v-switch>
          <v-select
            label="Buyers Position"
            v-model="fields.buyers_position"
            :items="positions"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('buyers_position')"
            :error-messages="errors['buyers_position']"
          ></v-select>

          <v-select
            label="Source"
            v-model="fields.source"
            :items="sources"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('source')"
            :error-messages="errors['source']"
          ></v-select>

          <v-switch
            label="Seller"
            v-model="fields.seller"
            inset
            :error="errors.hasOwnProperty('seller')"
            :error-messages="errors['seller']"
          ></v-switch>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="reset()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="customer-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import * as countries from "i18n-iso-countries";
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

export default {
  data() {
    return {
      dialog: false,
      loading: false,
      isEditing: false,
      customer: {},
      fields: {
        first_name: null,
        last_name: null,
        email: null,
        phone: null,
        address1: null,
        is_actively_looking: false,
        address2: null,
        city: null,
        postcode: null,
        county: null,
        buyers_position: null,
        source: null,
        seller: false,
        country: "United Kingdom",
      },
      positions: [
        "FTB",
        "CASH BUYER",
        "UNDER OFFER",
        "IN RENTED",
        "PROPERTY TO SELL",
      ],
      sources: ["Rightmove", "OnTheMarket", "Zoopla", "ESPC", "M&P", "Sign"],
      errors: {},
    };
  },

  computed: {
    availableCountries() {
      return Object.values(countries.getNames("en"));
    },
  },

  methods: {
    open(customer = null) {
      if (customer !== null) {
        this.customer = customer;
        this.isEditing = true;
        this.fields.first_name = customer.first_name;
        this.fields.last_name = customer.last_name;
        this.fields.email = customer.email;
        this.fields.phone = customer.phone;
        this.fields.source = customer.source;
        this.fields.seller = customer.seller;
        this.fields.is_actively_looking = customer.is_actively_looking;

        if (customer.current_address) {
          this.fields.address1 = customer.current_address.address1;
          this.fields.address2 = customer.current_address.address2;
          this.fields.city = customer.current_address.city;
          this.fields.postcode = customer.current_address.postcode;
          this.fields.county = customer.current_address.county;
          this.fields.country = customer.current_address.country;
        }

        this.fields.buyers_position = customer.buyers_position;
      }

      this.dialog = true;
    },

    save() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      if (this.isEditing) {
        payload.customerId = this.customer.id;
      }

      this.$store
        .dispatch("melrose/customersStore/saveCustomer", payload)
        .then(() => {
          this.reset();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    reset() {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.customer = {};
      this.fields = {
        first_name: null,
        last_name: null,
        email: null,
        phone: null,
        address1: null,
        is_actively_looking: false,
        address2: null,
        city: null,
        seller: false,
        postcode: null,
        county: null,
        buyers_position: null,
        source: null,
        country: "United Kingdom",
      };
      this.$refs.formWrapper.scrollTop = 0;
    },
  },
};
</script>
